
import { mapState } from "vuex";
import authApi from "@/api/common/auth";
import moment from "moment";
export default {
    data() {
        return {
            sortRankingBy: 'customerTakeCare',
            slogan: [
                "Mất ngủ vì không có đối thủ",
                "Tăng tốc để bứt phá",
                "Hiệu quả lan toả niềm tin",
                "Kết sức mạnh, nối thành công",
                "Đột phá để thành công",
                "Vượt gian nan, đập tan thách thức",
                "Có kết quả, mới có giá trị",
                "Có điều kiện thì cứ thể hiện",
                "Thành phố tắt đèn, chúng ta xuất hiện",
                "Sinh ra là để tỏa sáng",
            ],
            rankingUserOfDepartment: [],
            timeRanking: moment().format("YYYY-MM"),
            viewTime: 'InTime',
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
        }),    
        sumCustomer() {
            switch (this.sortRankingBy) {
                case 'customerTakeCare':
                    return this.rankingUserOfDepartment.reduce((total, item) => {
                        return total + item.customerTakeCare;
                    }, 0);
                case 'customerSeeding':
                    return this.rankingUserOfDepartment.reduce((total, item) => {
                        return total + item.customerSeeding;
                    }, 0);
                case 'customerRecharge':
                    return this.rankingUserOfDepartment.reduce((total, item) => {
                        return total + item.customerRecharge;
                    }, 0);
                case 'customerUsingTime':
                    return this.rankingUserOfDepartment.reduce((total, item) => {
                        return total + item.customerUsingTime;
                    }, 0);
            }
        },
    },
    mounted() {
        this.getRankingUserOfDepartment(null);
        this.$store.dispatch('getListStaff');
    },
    methods : {
        getRankingUserOfDepartment(idDept, timeRanking) {
            authApi.getRankingUserOfDepartment(idDept, timeRanking).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    let dataStaffs = response.data.data.filter(x => !['developer', 'ketoanir'].includes(x.code));
                    this.$set(this, "rankingUserOfDepartment", this.sortedRanking(dataStaffs));
                }
                else {
                    this.rankingUserOfDepartment = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.userAvailable = [];
            });
        },
        sortedRanking(source) {
            return source.sort((a, b) => {
                if (a[this.sortRankingBy] < b[this.sortRankingBy]) {
                    return 1;
                }
                if (a[this.sortRankingBy] > b[this.sortRankingBy]) {
                    return -1;
                }
                // a bằng b
                return 0;
            });
        },
        sortRankingByChanged() {
            this.getRankingUserOfDepartment(null, this.viewTime === 'All' ? null : this.timeRanking);
        }
    },
}